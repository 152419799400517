import React from 'react'

const About = () => {
  return (
    <>
    <h1 className='text-center text-lg font-bold'>About Page</h1>
    </>
  )
}

export default About;