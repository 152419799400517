import React, { useState, useEffect } from "react";
import { FaRobot } from "react-icons/fa";
import TopNavbar from "./navbar/TopNavbar";
import { ColorRing } from "react-loader-spinner";

const HomePage = ({ searchQuery }) => {
  const [bots, setBots] = useState([]);
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState();

  useEffect(() => {
    setLanguage(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    const fetchBotData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/get-bot-data`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);

        setBots(data);
      } catch (err) {
        console.error("Error fetching bot data:", err.message);
        setError(`Failed to load bot data: ${err.message}`);
      }
    };

    fetchBotData();
  }, []);

  return (
    <>
      <div className="flex flex-col sm:flex-row m-2 p-2">
        {bots.length != 0 ? (
          bots.map((bot) => (
            <div
              key={bot.id}
              className="w-full pt-6 m-3 max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
            >
              <div className="flex pt-4 flex-col items-center pb-10">
                <div className="w-24 h-24 mb-3 rounded-full shadow-lg">
                  <div className="flex justify-center align-center pt-6">
                    <FaRobot className="text-5xl text-center" />
                  </div>
                </div>
                <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                  {bot["bot-name"]}
                </h5>
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {bot["bot-type"]}
                </span>
                <div className="flex mt-4 md:mt-6">
                  <a
                    href={`/bot?id=${bot.id}&lang=${
                      language ? language : "en-US"
                    }`}
                    className="inline-flex items-center px-6 py-2 text-md font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Start
                  </a>

                  <a
                    href={`/training?id=${bot.id}`}
                    className="px-6 py-2 text-md ms-2 inline-flex items-center px-4 py-2 font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Train
                  </a>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="flex justify-center w-full h-screen">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default HomePage;
