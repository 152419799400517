import React, { useState, useCallback, useEffect } from "react";
import { Modal, Button } from "flowbite-react";
import "../styles.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";

const subscriptionKey = process.env.REACT_APP_AZURE_SPEECH_KEY;
const serviceRegion = process.env.REACT_APP_AZURE_SPEECH_LOCATION;
const deployment = process.env.REACT_APP_ENV;
// Initialize speech configuration outside the component to avoid re-initialization
const speechConfig = sdk.SpeechConfig.fromSubscription(
  subscriptionKey,
  serviceRegion
);
speechConfig.speechSynthesisVoiceName = "en-US-EmmaMultilingualNeural";

const Bot = () => {
  const [transcript, setTranscript] = useState("");
  const [appState, setAppState] = useState("idle");
  const [chatResponse, setChatResponse] = useState("");
  const [showSpeechBubble, setShowSpeechBubble] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [sttTime, setSttTime] = useState();
  const [azSearchTime, setAzSearchTime] = useState();
  const [azOpenAiTime, setAzOpenAiTime] = useState();
  const [processTime, setProcessTime] = useState();
  const [ttsTime, setTtsTime] = useState();
  const [endCallTime, setEndCallTime] = useState();
  const [usage, setUsage] = useState(0);
  const [userId, setUserId] = useState("");
  const location = useLocation();
  const [ttsPrice, setTtsPrice] = useState(0);
  const [totalTtsPrice, setTotalTtsPrice] = useState(ttsPrice);
  const [sttPrice, setSttPrice] = useState(0);
  const [totalSttPrice, setTotalSttPrice] = useState(sttPrice);
  const [openModal, setOpenModal] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const [totalLetancy, setTotalLetancy] = useState(0);
  const id = queryParams.get("id");
  const language = queryParams.get("lang");

  useEffect(() => {
    // Cleanup if necessary
    return () => {
      // Add any cleanup code here
    };
  }, []);

  const handleCallButtonClick = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/start-the-call`
      );
      if (response.status === 200) {
        setUserId(response.data.userId);
        processTranscript("hello", new Date().getTime(), response.data.userId);
        //processTranscript("hello", response.data.userId);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  }, []);

  // Function to calculate price based on audio duration in seconds
  const calculateSpeechToTextPrice = (audioDuration) => {
    audioDuration = audioDuration / 1000;
    const audioHours = audioDuration / 3600; // Convert duration from seconds to hours
    const pricePerHour = 1; // $1 per audio hour
    const priceForCurrentAudio = audioHours * pricePerHour;
    setSttPrice(priceForCurrentAudio);
    setTotalSttPrice(
      (prevTotalSttPrice) => prevTotalSttPrice + priceForCurrentAudio
    );
  };

  const startSpeechRecognition = useCallback(
    (userid) => {
      const startTime = new Date().getTime();
      const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
      const autoDetectSourceLanguageConfig =
        sdk.AutoDetectSourceLanguageConfig.fromLanguages([language]);
      autoDetectSourceLanguageConfig.mode = sdk.LanguageIdMode.Continuous;

      const speechRecognizer = sdk.SpeechRecognizer.FromConfig(
        speechConfig,
        autoDetectSourceLanguageConfig,
        audioConfig
      );
      speechRecognizer.recognizeOnceAsync((result) => {
        handleRecognitionResult(result, startTime, userid);
        speechRecognizer.close(); // Ensure resources are cleaned up
      });

      setShowSpeechBubble(true);
    },
    [speechConfig]
  );

  const handleRecognitionResult = (result, startTime, userid) => {
    switch (result.reason) {
      case sdk.ResultReason.RecognizedSpeech:
        setShowSpeechBubble(true);
        setTranscript(result.text || "No content received");
        const audioDuration = new Date().getTime() - startTime;
        setSttTime(audioDuration);

        calculateSpeechToTextPrice(audioDuration);
        processTranscript(result.text, userid);
        break;
      case sdk.ResultReason.NoMatch:
        console.log("NOMATCH: Speech could not be recognized.");
        toggleRecording(userid);
        break;
      case sdk.ResultReason.Canceled:
        const cancellation = sdk.CancellationDetails.fromResult(result);
        console.log(`CANCELED: Reason=${cancellation.reason}`);
        if (cancellation.reason === sdk.CancellationReason.Error) {
          console.log(`CANCELED: ErrorCode=${cancellation.ErrorCode}`);
          console.log(`CANCELED: ErrorDetails=${cancellation.errorDetails}`);
        }
        break;
      default:
        break;
    }
  };
  const [msgCount, setMsgCount] = useState(0);
  //const processTranscript = useCallback(async (text, startTime, userid) => {
  const processTranscript = useCallback(
    async (text, userid) => {
      try {
        setAppState("processing");
        setShowSpinner(true);
        setMsgCount((prevCount) => {
          const totalMsg = prevCount + 1;
          console.log(totalMsg); // Log the updated message count
          return totalMsg; // Update the state
        });
        const formData = new FormData();
        formData.append("query", text);
        formData.append("user_id", userid);
        formData.append("bot_id", `${id}`);
        const response = await axios.post(
          process.env.REACT_APP_API_URL,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        const responseText = response.data.response[0][0];

        setAzSearchTime(response.data.response[1] * 1000);
        setAzOpenAiTime(response.data.response[2] * 1000);

        const inputToken =
          response.data.response[0][1].prompt_tokens * 0.000005;
        const outputToken =
          response.data.response[0][1].completion_tokens * 0.000015;
        const totalTokens = inputToken + outputToken;
        const totalretime =
          response.data.response[1] * 1000 + response.data.response[2] * 1000;
        setTotalLetancy((prevTime) => prevTime + totalretime);
        setUsage((prevUsage) => prevUsage + totalTokens);
        setChatResponse(responseText || "No content received");
        setChatHistory((prevHistory) => [
          ...prevHistory,
          { query: text, response: responseText },
        ]);
        speak(responseText, userid);
      } catch (error) {
        console.error("Error processing transcript:", error);
      } finally {
        setAppState("idle");
        setShowSpinner(false);
      }
    },
    [id]
  );

  const calculatePrice = (text) => {
    const charactersCount = text.length;
    const pricePerMillion = 15; // $15 per million characters
    const priceForCurrentText = (charactersCount / 1000000) * pricePerMillion;
    setTtsPrice(priceForCurrentText);
    setTotalTtsPrice(
      (prevTotalTtsPrice) => prevTotalTtsPrice + priceForCurrentText
    );
  };

  const speak = useCallback(
    (text, userid) => {
      const startTime = new Date().getTime();
      const synthesizer = new sdk.SpeechSynthesizer(speechConfig);
      const autoDetectSourceLanguageConfig =
        sdk.AutoDetectSourceLanguageConfig.fromLanguages([language]);
      autoDetectSourceLanguageConfig.mode = sdk.LanguageIdMode.Continuous;

      synthesizer.speakTextAsync(
        text,
        (result) => {
          if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
            const ttstime = new Date().getTime() - startTime;
            setTtsTime(ttstime);
            setTotalLetancy((prevTime) => prevTime + ttstime);
            setTimeout(
              () => toggleRecording(userid),
              result.privAudioDuration / 10000
            );
            calculatePrice(text);
          }
          synthesizer.close();
        },
        (error) => {
          console.error("Speech synthesis failed:", error);
          synthesizer.close();
        }
      );
    },
    [speechConfig]
  );

  const toggleRecording = useCallback(
    (userid) => {
      setAppState("listening");
      startSpeechRecognition(userid);
    },
    [startSpeechRecognition]
  );

  const toggleChat = () => setIsChatOpen((prevState) => !prevState);

  const endCall = useCallback(async () => {
    window.location.reload();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/complete-the-call`,
        { user_id: userId },
        { headers: { "Content-Type": "application/json" } }
      );
      console.log("Call ended successfully:", response.data);
    } catch (err) {
      console.error("Error ending the call:", err);
    }
  }, [userId]);

  return (
    <div className="containerr">
      <header className="app-header">
        <h1 className="text-3xl">HamariAI Voice Bot</h1>
        {/* <p className="app-subtext">Speech To Text Time: {sttTime}ms</p> */}
        {deployment === "production" ? (
          ""
        ) : (
          <div>
            <p className="app-subtext">
              Azure Search Time: {azSearchTime && azSearchTime.toFixed()}ms
            </p>
            <p className="app-subtext">
              Azure OpenAI Time: {azSearchTime && azOpenAiTime.toFixed()}ms
            </p>
            <p className="app-subtext">Text To Speech Time: {ttsTime}ms</p>
            <p className="app-subtext">
              Total Time: {(azSearchTime + azOpenAiTime + ttsTime).toFixed()}ms
            </p>
            <p className="app-subtext ">
              Text to Speech Price: $ {ttsPrice.toFixed(4)}
            </p>
            <p className="app-subtext ">
              Total Text to Speech Price: $ {totalTtsPrice.toFixed(4)}
            </p>
            <p className="app-subtext ">
              Speech To Text Price: $ {sttPrice.toFixed(4)}
            </p>
            <p className="app-subtext ">
              Total Speech To Text Price: $ {totalSttPrice.toFixed(4)}
            </p>
            <p className="app-subtext ">
              Total Price: ${" "}
              {(totalSttPrice + totalTtsPrice + usage).toFixed(4)}
            </p>
          </div>
        )}
      </header>

      <div className={`speech-bubble ${showSpeechBubble ? "visible" : ""}`}>
        {transcript}
      </div>

      <div className={`app-state-indicator ${appState}`}>
        {appState === "listening" && (
          <div className="listening-bubble">You can speak now...</div>
        )}
        <button className="record-btn" onClick={toggleRecording}>
          {showSpinner && <div className="spinner"></div>}
        </button>

        <button
          className="start-btn"
          onClick={handleCallButtonClick}
          disabled={appState !== "idle"}
        >
          Call
        </button>
        {deployment === "production" ? (
          <button
            className="stop-btn"
            onClick={() => {
              endCall();
            }}
          >
            Hang Up
          </button>
        ) : (
          <button
            className="stop-btn"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Hang Up
          </button>
        )}
      </div>

      <div className={`chat-sidebar ${isChatOpen ? "open" : ""}`}>
        <div className="chat-content">
          {chatHistory.map((entry, index) => (
            <div key={index} className="chat-entry">
              <div className="user-query">User: {entry.query}</div>
              <div className="system-response">Response: {entry.response}</div>
            </div>
          ))}
        </div>
      </div>

      <button className="chat-toggle-button" onClick={toggleChat}>
        {isChatOpen ? "<" : ">"}
      </button>

      <Modal
        show={openModal}
        className="w-96 m-auto"
        onClose={() => {
          setOpenModal(false);
          endCall();
        }}
      >
        <Modal.Header className="p-2">Hang Up Call</Modal.Header>
        <Modal.Body>
          <div className="space-y-6 p-2">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Total Cost : ${" "}
              {(totalSttPrice + totalTtsPrice + usage).toFixed(4)}{" "}
            </p>
            <p className="text-base  leading-relaxed text-gray-500 dark:text-gray-400">
              {" "}
              Average latency : {(totalLetancy / msgCount).toFixed()} ms
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="">
          {/* <Button onClick={() => setOpenModal(false)}>I accept</Button> */}
          <Button
            className="text-center bg-red-700 m-auto my-2 leading-relaxed text-white dark:text-gray-400"
            onClick={() => {
              setOpenModal(false);
              setAppState("idle");
              endCall();
            }}
          >
            End Call
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Bot;
