import React, { useState } from "react";
import "./index.css";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Bot from "./components/Bot";
import HomePage from "./components/HomePage.jsx";
import Train from "./components/Training.jsx";
import TopNavbar from "./components/navbar/TopNavbar.jsx";
import Services from "./pages/Services.jsx";
import About from "./pages/About.jsx";
import Pricing from "./pages/Pricing.jsx";
import Contact from "./pages/Contact.jsx";

const App = () => {
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  return (
    <>
      {location.pathname !== "/bot" && (
        <TopNavbar setSearchQuery={setSearchQuery} />
      )}
      <Routes>
        <Route path="/" element={<HomePage searchQuery={searchQuery} />} />
        <Route path="/training" element={<Train />} />
        <Route path="/bot" element={<Bot />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  );
};

const RootApp = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default RootApp;
